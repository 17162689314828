import Vue from 'vue'
import Router from 'vue-router'
import store from './store';

Vue.use(Router)

 const router = new Router({
  routes: [
    {
      path: '/login',
      name: 'login',
      component: () => import('./views/Login.vue')
    },
    {
      path: '/expenses',
      name: 'expenses',
      component: () => import('./views/Expenses.vue')
    },
    {
      path: '*',
      redirect: '/login'
    }
  ]
});

router.beforeEach((to, from, next) => {
  if(to.fullPath === '/expenses')
  {
      if(!store.state.loginSuccessful)
        next('/login');
      else
        next();
  }
  else if(to.fullPath === '/login')
  {
    if(store.state.loginSuccessful)
      next('/expenses');
    else
      next();
  }
  else {
     next();
  }
});

export default router;