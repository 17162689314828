import Vue from 'vue'
import Vuex from 'vuex'
import router from './router';
import post from './utility'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        loggingIn: false,
        loginError: null,
        loginSuccessful: false,
        credentials: {},
        submittingExpenseError: null,
        submittingExpense: false,
        submittingExpenseSuccessful: false,
    },
    mutations: {
        loginStart: (state, credentials) => {
            state.loggingIn = true;
            state.loginSuccessful= false;
            state.loginError = null;
            state.credentials = credentials;
        },
        loginStop: (state, errorMessage) => {
            state.loggingIn = false;
            state.loginError = errorMessage;
            state.loginSuccessful = !errorMessage;
            if(state.loginSuccessful)
                router.push('/expenses');
        },
        submitExpenseStart: (state) => {
            state.submittingExpense = true;
            state.submittingExpenseSuccessful = false;
            state.submittingExpenseError = null;
        },
        submitExpenseStop: (state, errorMessage) => {
            state.submittingExpense = false;
            state.submittingExpenseError = errorMessage;
            state.submittingExpenseSuccessful = !errorMessage;
        }
    },
    actions: {
        doLogin({commit}, credentials) {
            commit('loginStart', credentials);
            post("login").then(response => {
                commit('loginStop', response.data.message);
            }).catch(error => {
                commit('loginStop', error);
            });
        },
        submitExpense({commit}, data){
            return new Promise((resolve, reject) => {
                commit('submitExpenseStart');
                post("addExpense", data).then(response => {
                    if(response.data.status === 'error')
                    {
                        commit('submitExpenseStop', response.data.message);
                        reject();
                    }
                    else
                    {
                        commit('submitExpenseStop', null);
                        resolve();
                    }
                }).catch(error => {
                    commit('submitExpenseStop', error);
                    reject();
                });
            });
        },
    }
})
