import axios from 'axios'
import {config} from './config'
import store from './store'

export default function post(method, parameters){
    parameters = parameters ? parameters: {};
    parameters.username = store.state.credentials.username;
    parameters.password = store.state.credentials.password;

    let data = {
        method: method,
        parameters: parameters,
    };

    let axiosConfig = {
      headers: {
          'Content-Type': 'text/plain' //This may trick google apps script into accepting the request through CORS
      }
    };

    return axios.post(config.apiUrl, data, axiosConfig);
}